import { FC, useEffect, useState } from 'react';
import { orderBy } from 'natural-orderby';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import SublocationsSelect from 'src/components/Items/select/SublocationsSelect';
import SelectBaseOnId from 'src/components/Items/select/SelectBaseOnId';
import NumberInput from 'src/components/Items/input/NumberInput';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { SubPrice } from 'src/entity/SubPrice';
import { ISubPriceFetch } from 'src/models/ISubPrice';
import { ILocationFetch } from 'src/models/ILocation';

interface IProps {
  subPrice: ISubPriceFetch | SubPrice;
  setSubPrice: any;
  check: boolean;
}

const SubPriceFormModal: FC<IProps> = ({ subPrice, setSubPrice, check }) => {
  const { getLocations } = useActions();
  const {
    location: { locationList },
    host: { hostList }
  } = useTypedSelector(state => state);

  const [locations, setLocations] = useState<ILocationFetch[]>([]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    const locationsWithSub = locationList.filter(location => location.sub_location!.length);
    setLocations(locationsWithSub);
  }, [locationList]);

  return (
    <>
      <SublocationsSelect
        data={subPrice.location as string}
        setData={setSubPrice('location')}
        dataArray={locations}
        placeholder='Sub-location'
        title='location'
        check={check}
        type='with id'
      />
      {/* <SelectBaseOnId
        dataId={Number(subPrice.host_id)}
        setDataId={setSubPrice('host_id')}
        title='host'
        dataArray={hostList}
        propsShow={['first_name', 'last_name']}
        check={check}
      /> */}
      <div className='col-2'>
        <NumberInput
          data={subPrice.price}
          setData={setSubPrice('price')}
          placeholder='Price'
          title='Price'
          unlimit
          check={check}
          text
        />
        <SimpleSelect
          data={subPrice.currency}
          setData={setSubPrice('currency')}
          dataArray={['USD', 'EUR']}
          placeholder='currency'
          title='currency'
          check={check}
        />
      </div>
    </>
  );
};

export default SubPriceFormModal;
