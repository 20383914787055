import { FC, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import SublocationItem from '../../Sublocation/Item';
import AddSublocationModal from '../../Sublocation/Add';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { getNames } from 'src/common/utils/others';
import { ILocation } from 'src/models/ILocation';
import { IErrorsLocationData } from '../index';
import './styles.scss';

interface IProps {
  location: ILocation;
  locationId?: number;
  setLocation: any;
  check: boolean;
  errors: IErrorsLocationData;
}

const LocationFormModal: FC<IProps> = ({ location, locationId, setLocation, check, errors }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    sublocation: { isLoading, result },
    tag: { tagList }
  } = useTypedSelector(state => state);
  const locations = useTypedSelector(state => state.location);
  const [open, setOpen] = useState(false);
  const [sublocations, setSublocations] = useState(location.sub_location);

  const regionTags = tagList.filter(tag => (tag.category as any) === 'region');

  useEffect(() => {
    locations.result.all &&
      locations.locationList.map(el => el.id === locationId && setSublocations(el.sub_location));
  }, [locations.result.all]);

  useEffect(() => {
    isLoading.add === false &&
      result.add &&
      (result.add === 200
        ? enqueueSnackbar('Sublocations successfully added', {
            variant: 'success',
            style: { color: `white !important` }
          })
        : enqueueSnackbar("Sublocations didn't added", { variant: 'error' }));
  }, [isLoading.add, result.add]);

  useEffect(() => {
    isLoading.update === false &&
      result.update &&
      (result.update === 200 || result.update === 201
        ? enqueueSnackbar('Sublocation successfully updated', {
            variant: 'success',
            style: { color: `white !important` }
          })
        : enqueueSnackbar("Sublocation didn't updated", { variant: 'error' }));
  }, [isLoading.update, result.update]);

  useEffect(() => {
    isLoading.update === false &&
      result.update &&
      (result.update === 200 || result.update === 201
        ? enqueueSnackbar('Sublocation successfully deleted', {
            variant: 'success',
            style: { color: `white !important` }
          })
        : enqueueSnackbar("Sublocation didn't deleted", { variant: 'error' }));
  }, [isLoading.delete, result.delete]);

  return (
    <>
      <AddSublocationModal
        locationId={locationId!}
        locationName={location.name}
        close={open}
        handleClose={() => setOpen(false)}
      />
      <div className='col-3' style={{ marginBottom: '20px' }}>
        <SimpleInput
          data={location['name']!}
          setData={setLocation('name')}
          title={'name'}
          check={check}
          error={errors.name}
          length={50}
          isHelperText={true}
        />
        <SimpleInput
          data={location['country']!}
          setData={setLocation('country')}
          title={'country'}
          check={check}
          error={errors.country}
          length={50}
          isHelperText={true}
        />
        <SimpleInput
          data={location['review_link']!}
          setData={setLocation('review_link')}
          title={'review link'}
          check={check}
          error={errors.review_link}
          isHelperText={true}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        <SimpleMultiSelect
          data={getNames(location['tags'])}
          setData={setLocation('tags')}
          dataArray={getNames(regionTags)}
          title='Region'
        />
      </div>
      {locationId && (
        <div className='nearby-locations'>
          <div className='filter__title nearby-locations__title'>Sublocations</div>
          <div className='nearby-locations__container'>
            {sublocations!.map((sub, i) => (
              <SublocationItem sublocation={sub} />
            ))}
            <Button
              children='+'
              variant='contained'
              className='add-sublocation-button'
              onClick={() => setOpen(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationFormModal;
