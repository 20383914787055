import { FC, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ActionsItem from './Item';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import useIsLaptop from 'src/hooks/useIsLaptop';
import { IOrderFetch } from 'src/models/IOrder';
import './style.scss';

interface IProps {
  order: IOrderFetch;
}

const Actions: FC<IProps> = ({ order }) => {
  const { actionOrder } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const isLaptop = useIsLaptop();
  const { role } = useTypedSelector(state => state.auth);
  const { isLoading, result, action } = useTypedSelector(state => state.order);
  const daysToTripStart = DateTime.fromISO(order.start_date).diffNow('days').days;

  useEffect(() => {
    if (isLoading.action === false && result.action) {
      if (action.data === 'email') {
        result.action === 200
          ? enqueueSnackbar('Email sent successfully', {
              variant: 'success'
            })
          : enqueueSnackbar('Email was not sent', { variant: 'error' });
      } else if (action.data === 'hold amount') {
        result.action === 200
          ? enqueueSnackbar('Hold Amount was successful', {
              variant: 'success'
            })
          : enqueueSnackbar('Hold Amount failed', { variant: 'error' });
      } else if (action.data === 'refund') {
        result.action === 200
          ? enqueueSnackbar('Refund was successful', {
              variant: 'success'
            })
          : enqueueSnackbar('Refund failed', { variant: 'error' });
      }
    }
  }, [isLoading.action, result.action]);

  return (
    <>
      <div className='order-show__title'>Actions</div>
      <div className='order-notification__list__item'>
        <Badge>{isLaptop ? 'Adjusted Pay' : 'Adjusted Pay: Makes Invoice+Emails'}</Badge>
        <div className='order-notification__action'>
          <div style={{ marginRight: '3px' }} className='order-notification__action__item'>
            {isLoading.action ? (
              <CircularProgress size={15} />
            ) : (
              <Tooltip title='Send email'>
                <Badge>
                  <IconButton
                    disabled={daysToTripStart > 2}
                    onClick={() => actionOrder(order.id, 'due amount')}
                  >
                    <AlternateEmailIcon />
                  </IconButton>
                </Badge>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <div className='order-notification__list__item'>
        <Badge>{isLaptop ? 'Re-sign RA' : 'Re-sign RA: Deletes Old, emails 2 re-sign'}</Badge>
        <div className='order-notification__action'>
          <div
            className='order-notification__action__item'
            style={{ marginRight: '3px', cursor: 'pointer' }}
          >
            {isLoading.action ? (
              <CircularProgress size={15} />
            ) : (
              <Tooltip title='Send email'>
                <Badge>
                  <AlternateEmailIcon onClick={() => actionOrder(order.id, 'resign')} />
                </Badge>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {order.stripe_charge_id && (
        <>
          <ActionsItem
            title={isLaptop ? 'Cancel 4 guest' : 'Cancel 4 guest: deletes, refunds+email'}
            name='cancel 4 guest'
            order={order}
          />
          <ActionsItem
            title={
              isLaptop
                ? 'Sorry No Van'
                : 'Sorry No Van: Emails+Deletes. Try Giving To Other Hosts be4 & Block Your Van'
            }
            name='sorry no van'
            order={order}
          />
          {role === 'admin' && (
            <ActionsItem title='Refund Down-Pay Only' name='refund only' order={order} />
          )}
        </>
      )}
    </>
  );
};

export default Actions;
