import { FC, useMemo, useState } from 'react';
import sum from 'lodash/sum';
import { CircularProgress, Skeleton } from '@mui/material';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import NumberInput from 'src/components/Items/input/NumberInput';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroSubtract } from 'src/common/utils/dataFormat';
import { IExtra, ManualType } from 'src/models/IOrder';
import { IVan, IVanFetch } from 'src/models/IVan';
import Icons from 'src/common/icons';

interface IProps {
  item: IVanFetch | IExtra;
  title: ManualType;
  days?: number;
  index?: number;
}

const CartItem: FC<IProps> = ({ item, title, days, index }) => {
  const {
    order,
    isLoading: { get, update }
  } = useTypedSelector(state => state.order);
  const { id, old_price, fee_list, sub_location } = order!;
  const { updateOrder } = useActions();
  const subId = process.env.REACT_APP_NODE_ENV ? 18 : 27;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [price, setPrice] = useState(0);

  const onSubmit = () => {
    if (title === 'van') {
      updateOrder({ price: price * 100, id });
    } else {
      const fee = fee_list[index!];
      const editedFeeList = [...fee_list];
      editedFeeList[index!] = { ...fee, price: price * 100 };
      updateOrder({ fee_list: editedFeeList, id });
    }

    handleClose();
  };

  useMemo(() => {
    setPrice(item.price! / 100);
  }, [open]);

  const _renderPrice = (item: IVanFetch | IExtra, title: 'van' | 'extras') =>
    title === 'van' ? (
      open ? (
        <div className='order-cart__item__price'>
          <div className='order-cart__item__price__action' onClick={handleClose}>
            <Icons.Cross />
          </div>
          <NumberInput
            data={price}
            setData={setPrice}
            variant='outlined'
            style={{ height: '20px' }}
          />
          <div className='order-cart__item__price__action' onClick={onSubmit}>
            <Icons.ArrowDown />
          </div>
        </div>
      ) : old_price !== 0 ? (
        <div
          className={`order-cart__item__price ${old_price !== 0 ? 'old' : ''}`}
          onClick={handleOpen}
        >
          {update ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {dineroFormat(old_price!, item.currency)}
              <div className='order-cart__item__price new'>
                {dineroSubtract(order!.price, sum(fee_list?.map(i => i.price)), item.currency)}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className='order-cart__item__price' onClick={handleOpen}>
          {dineroSubtract(order!.price, sum(fee_list.map(i => i.price)), item.currency)}
        </div>
      )
    ) : open ? (
      <div className='order-cart__item__price'>
        <div className='order-cart__item__price__action' onClick={handleClose}>
          <Icons.Cross />
        </div>
        <NumberInput
          data={price}
          setData={setPrice}
          variant='outlined'
          style={{ height: '20px' }}
          unlimit
        />
        <div className='order-cart__item__price__action' onClick={onSubmit}>
          <Icons.ArrowDown />
        </div>
      </div>
    ) : (
      <div className='order-cart__item__price' onClick={handleOpen}>
        {dineroFormat(item.price, item.currency)}
      </div>
    );

  return title === 'van' ? (
    <div className='order-cart__van'>
      <div className='order-cart__van__container'>
        <div className='order-cart__van__img'>
          {get ? (
            <Skeleton width='100%' height='100%' />
          ) : (
            <img src={(item as IVan).photos?.exterior[0]} alt='van' loading='lazy' />
          )}
        </div>
        {_renderPrice(item, 'van')}
      </div>
      <div className='order-cart__van__title'>
        {(item as IVan).mark}
        <a
          href={`https://van.sprinter-rentals.com/van/143${item.id}`}
          target='_blank'
          className='order-cart__van__link'
        >
          here
        </a>
      </div>
    </div>
  ) : (
    <div className='order-cart__item'>
      <div className='order-cart__item__img'>
        {(item as IExtra).id === subId ? (
          <AddLocationAltIcon fontSize='large' />
        ) : (
          <img src={(item as IExtra).logo} alt='extra' loading='lazy' />
        )}
      </div>
      <div className='order-cart__item__info'>
        <div className='order-cart__item__info__title'>
          {(item as IExtra).id === subId ? sub_location : (item as IExtra).name}
        </div>
        {/* <div className='order-cart__item__info__description'>
          {(item as IExtra).title}
        </div> */}
      </div>
      {_renderPrice(item, 'extras')}
    </div>
  );
};
export default CartItem;
