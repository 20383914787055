import { FC } from 'react';
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { TextField, styled } from '@mui/material';
import { colorSet, typeForm } from 'src/theme';

interface IProps {
  data: number;
  setData: any;
  min?: number;
  max?: number;
  title?: string;
  placeholder?: string;
  check?: boolean;
  unlimit?: boolean;
  error?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  text?: boolean;
  style?: {};
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

const StyledInput = styled(TextField)({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  'input[type=number]': {
    MozAppearance: 'textfield'
  }
});

const NumberInput: FC<IProps> = ({
  data,
  setData,
  min = 0,
  max,
  title,
  placeholder = title,
  unlimit,
  check,
  error,
  variant,
  text,
  style
}) => {
  const minValue = unlimit ? undefined : min;

  const helperText = () => {
    if (check) {
      if (error) return error;
      else if (!data) return 'Fill the field';
      else return;
    } else return placeholder;
  };

  return (
    <div style={style}>
      {title && <div className='filter__title'>{title}</div>}
      <StyledInput
        placeholder={helperText()}
        variant={variant ?? typeForm}
        autoComplete='off'
        InputProps={{
          inputComponent: text && (NumericFormatCustom as any),
          inputProps: {
            min: minValue,
            max
          }
        }}
        value={String(data) ?? ''}
        onChange={event => {
          let value = +event.target.value.replace(/,/, '.');
          if (isNaN(value)) value = 0;
          if (minValue !== undefined && minValue >= 0 && Math.sign(value) === -1) {
            value = Math.abs(value);
          }
          if (minValue && value < minValue) value = minValue;
          if (max && value > max) value = max;
          setData(value);
        }}
        error={!data && check}
        helperText={error ? error : !data && check && "Field can't be empty"}
        type={text ? 'string' : 'number'}
        sx={{
          input: {
            '::placeholder': {
              color: check && !data ? colorSet.primaryRed : colorSet.primaryGrey,
              opacity: 1
            }
          }
        }}
      />
    </div>
  );
};
export default NumberInput;
