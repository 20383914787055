import { Currency } from 'dinero.js';
import {
  ICharge,
  IManualItemOrderFetch,
  IOrderChangeDates,
  IOrderFetch,
  IPartialRefund
} from 'src/models/IOrder';
import { IPagination } from 'src/models/IPagination';
import {
  AddOrderAction,
  OrderActionEnum,
  DeleteOrderAction,
  GetOrderAction,
  GetOrdersAction,
  ResultAddOrderAction,
  ResultDeleteOrderAction,
  ResultGetOrderAction,
  ResultGetOrdersAction,
  ResultUpdateOrderAction,
  UpdateOrderAction,
  NotificationEmailOrderAction,
  ResultNotificationEmailOrderAction,
  NotificationSmsOrderAction,
  ResultNotificationSmsOrderAction,
  OrderNotificationActionEnum,
  InitNotificationOrderAction,
  OrderNotificationToGuestAdditionEnum,
  OrderNotificationToGuestAutomaticallyEnum,
  OrderNotificationToHostsEnum,
  ErrorGetOrderAction,
  UpdatePriceOrderAction,
  AddPaymentOrderAction,
  ResultAddPaymentOrderAction,
  ResultDeletePaymentOrderAction,
  DeletePaymentOrderAction,
  UpdateExtraOrderAction,
  ClearOrderAction,
  GetPaymentLinkAction,
  ResultGetPaymentLinkAction,
  OrderPaymentLinkActionEnum,
  ActionOrderAction,
  ResultActionOrderAction,
  UpdatePaymentOrderAction,
  ResultUpdatePaymentOrderAction,
  RequestAfterTripChargesAction,
  ResultAfterTripChargesAction,
  NotificationCopySmsOrderAction,
  ResultNotificationCopySmsOrderAction,
  RefundPaymentOrderAction,
  ResultRefundPaymentOrderAction,
  AddPartialPaymentOrderAction,
  ResultAddPartialPaymentOrderAction,
  OrderNotificationToLeadsEnum,
  AfterChargePaymentAction,
  ResultAfterChargePaymentAction,
  TransferPaymentAction,
  ResultTransferPaymentAction,
  Base64Object,
  ShowContractAction,
  ResultShowContractAction,
  ShowLicenseAction,
  ResultShowLicenseAction,
  ShowInsuranceAction,
  ResultShowInsuranceAction,
  ChangeOrderDatesAction,
  ResultChangeOrderDatesAction
} from './types';

export const OrderActionCreators = {
  getOrders: (filter: IPagination): GetOrdersAction => ({
    type: OrderActionEnum.REQUEST_ORDERS,
    filter
  }),
  resultGetOrders: (count: number, orderList: IOrderFetch[]): ResultGetOrdersAction => ({
    type: OrderActionEnum.RESULT_ORDERS,
    count,
    orderList
  }),

  getOrder: (id: number): GetOrderAction => ({
    type: OrderActionEnum.REQUEST_ORDER,
    id
  }),
  resultGetOrder: (data: IOrderFetch): ResultGetOrderAction => ({
    type: OrderActionEnum.RESULT_ORDER,
    data
  }),
  errorGetOrder: (status: number): ErrorGetOrderAction => ({
    type: OrderActionEnum.ERROR_ORDER,
    status
  }),
  clearOrder: (): ClearOrderAction => ({
    type: OrderActionEnum.CLEAR_ORDER
  }),

  deleteOrder: (id: number): DeleteOrderAction => ({
    type: OrderActionEnum.REQUEST_DELETE_ORDER,
    id
  }),
  resultDeleteOrder: (status: number): ResultDeleteOrderAction => ({
    type: OrderActionEnum.RESULT_DELETE_ORDER,
    status
  }),

  addPaymentOrder: (
    order_id: number,
    price: number,
    currency: Currency,
    note: string,
    isDelete: boolean,
    paymentType: string,
    createBy: string
  ): AddPaymentOrderAction => ({
    type: OrderActionEnum.REQUEST_ADD_PAYMENT_ORDER,
    order_id,
    price,
    currency,
    note,
    isDelete,
    paymentType,
    createBy
  }),

  resultAddPaymentOrder: (status: number): ResultAddPaymentOrderAction => ({
    type: OrderActionEnum.RESULT_ADD_PAYMENT_ORDER,
    status
  }),

  addPartialPaymentOrder: (
    price: number,
    currency: Currency,
    note: string,
    order_id: number
  ): AddPartialPaymentOrderAction => ({
    type: OrderActionEnum.REQUEST_ADD_PARTIAL_PAYMENT_ORDER,
    price,
    currency,
    note,
    order_id
  }),

  resultAddPartialPaymentOrder: (status: number): ResultAddPartialPaymentOrderAction => ({
    type: OrderActionEnum.RESULT_ADD_PARTIAL_PAYMENT_ORDER,
    status
  }),

  updatePaymentOrder: (order_id: number, charges: ICharge[]): UpdatePaymentOrderAction => ({
    type: OrderActionEnum.REQUEST_UPDATE_PAYMENT_ORDER,
    order_id,
    charges
  }),

  resultUpdatePaymentOrder: (data: string, status: number): ResultUpdatePaymentOrderAction => ({
    type: OrderActionEnum.RESULT_UPDATE_PAYMENT_ORDER,
    data,
    status
  }),

  deletePaymentOrder: (id: number): DeletePaymentOrderAction => ({
    type: OrderActionEnum.REQUEST_DELETE_PAYMENT_ORDER,
    id
  }),

  resultDeletePaymentOrder: (status: number): ResultDeletePaymentOrderAction => ({
    type: OrderActionEnum.RESULT_DELETE_PAYMENT_ORDER,
    status
  }),

  refundPaymentOrder: (data: IPartialRefund): RefundPaymentOrderAction => ({
    type: OrderActionEnum.REQUEST_REFUND_PAYMENT_ORDER,
    data
  }),

  resultRefundPaymentOrder: (status: number): ResultRefundPaymentOrderAction => ({
    type: OrderActionEnum.RESULT_REFUND_PAYMENT_ORDER,
    status
  }),

  transferPayment: (id: number): TransferPaymentAction => ({
    type: OrderActionEnum.REQUEST_TRANSFER_PAYMENT,
    id
  }),

  resultTransferPayment: (status: number, data?: string): ResultTransferPaymentAction => ({
    type: OrderActionEnum.RESULT_TRANSFER_PAYMENT,
    status,
    data
  }),

  afterChargePayment: (
    order_id: number,
    amount: number,
    note: string
  ): AfterChargePaymentAction => ({
    type: OrderActionEnum.REQUEST_AFTER_CHARGE_PAYMENT,
    order_id,
    amount,
    note
  }),

  resultAfterChargePayment: (data: string, status: number): ResultAfterChargePaymentAction => ({
    type: OrderActionEnum.RESULT_AFTER_CHARGE_PAYMENT,
    data,
    status
  }),

  updatePriceOrder: (data: IManualItemOrderFetch): UpdatePriceOrderAction => ({
    type: OrderActionEnum.REQUEST_UPDATE_PRICE_ORDER,
    data
  }),

  updateOrder: (currentOrder: Partial<IOrderFetch>): UpdateOrderAction => ({
    type: OrderActionEnum.REQUEST_UPDATE_ORDER,
    currentOrder
  }),

  updateExtraOrder: (currentOrder: Pick<IOrderFetch, 'id' | 'extras'>): UpdateExtraOrderAction => ({
    type: OrderActionEnum.REQUEST_UPDATE_EXTRA_ORDER,
    currentOrder
  }),

  resultUpdateOrder: (status: number): ResultUpdateOrderAction => ({
    type: OrderActionEnum.RESULT_UPDATE_ORDER,
    status
  }),

  addOrder: (currentOrder: IOrderFetch): AddOrderAction => ({
    type: OrderActionEnum.REQUEST_ADD_ORDER,
    currentOrder
  }),
  resultAddOrder: (status: number): ResultAddOrderAction => ({
    type: OrderActionEnum.RESULT_ADD_ORDER,
    status
  }),
  actionOrder: (id: number, actionType: string): ActionOrderAction => ({
    type: OrderActionEnum.REQUEST_ACTION_ORDER,
    id,
    actionType
  }),
  resultActionOrder: (status: number, data?: any): ResultActionOrderAction => ({
    type: OrderActionEnum.RESULT_ACTION_ORDER,
    data,
    status
  }),
  getAfterTripCharges: (): RequestAfterTripChargesAction => ({
    type: OrderActionEnum.REQUEST_AFTERTRIPCHARGES
  }),
  resultGetAfterTripCharges: (data: ICharge[]): ResultAfterTripChargesAction => ({
    type: OrderActionEnum.RESULT_AFTERTRIPCHARGES,
    data
  }),
  showContract: (key: string): ShowContractAction => ({
    type: OrderActionEnum.REQUEST_SHOW_CONTRACT,
    key
  }),
  resultShowContract: (data: Base64Object): ResultShowContractAction => ({
    type: OrderActionEnum.RESULT_SHOW_CONTRACT,
    data
  }),
  showLicense: (key: string): ShowLicenseAction => ({
    type: OrderActionEnum.REQUEST_SHOW_LICENSE,
    key
  }),
  resultShowLicense: (data: Base64Object): ResultShowLicenseAction => ({
    type: OrderActionEnum.RESULT_SHOW_LICENSE,
    data
  }),
  showInsurance: (key: string): ShowInsuranceAction => ({
    type: OrderActionEnum.REQUEST_SHOW_INSURANCE,
    key
  }),
  resultShowInsurance: (data: Base64Object): ResultShowInsuranceAction => ({
    type: OrderActionEnum.RESULT_SHOW_INSURANCE,
    data
  }),
  changeOrderDates: (dates: IOrderChangeDates, orderId: number): ChangeOrderDatesAction => ({
    type: OrderActionEnum.REQUEST_CHANGE_DATES,
    dates,
    orderId
  }),
  resultChangeOrderDates: (status: number, data?: IOrderFetch): ResultChangeOrderDatesAction => ({
    type: OrderActionEnum.RESULT_CHANGE_DATES,
    status,
    data
  })
};
export const OrderNotificationActionCreators = {
  sentEmail: (
    orderId: number,
    typeNotification:
      | OrderNotificationToLeadsEnum
      | OrderNotificationToGuestAdditionEnum
      | OrderNotificationToHostsEnum
      | OrderNotificationToGuestAutomaticallyEnum
      | string,
    driverId?: number,
    handlePaymentId?: number | string
  ): NotificationEmailOrderAction => ({
    type: OrderNotificationActionEnum.REQUEST_EMAIL,
    orderId,
    typeNotification,
    driverId,
    handlePaymentId
  }),
  resultSentEmail: (
    status: number,
    orderId: number,
    typeNotification:
      | OrderNotificationToLeadsEnum
      | OrderNotificationToGuestAdditionEnum
      | OrderNotificationToHostsEnum
      | OrderNotificationToGuestAutomaticallyEnum
      | string
  ): ResultNotificationEmailOrderAction => ({
    type: OrderNotificationActionEnum.RESULT_EMAIL,
    status,
    orderId,
    typeNotification
  }),
  sendSms: (
    orderId: number,
    typeNotification:
      | OrderNotificationToLeadsEnum
      | OrderNotificationToGuestAdditionEnum
      | OrderNotificationToHostsEnum
      | OrderNotificationToGuestAutomaticallyEnum
  ): NotificationSmsOrderAction => ({
    type: OrderNotificationActionEnum.REQUEST_SMS,
    orderId,
    typeNotification
  }),
  resultSendSms: (status: number): ResultNotificationSmsOrderAction => ({
    type: OrderNotificationActionEnum.RESULT_SMS,
    status
  }),
  copySms: (
    orderId: number,
    typeNotification:
      | OrderNotificationToLeadsEnum
      | OrderNotificationToGuestAdditionEnum
      | OrderNotificationToHostsEnum
      | OrderNotificationToGuestAutomaticallyEnum
  ): NotificationCopySmsOrderAction => ({
    type: OrderNotificationActionEnum.REQUEST_COPY_SMS,
    orderId,
    typeNotification
  }),
  resultCopySms: (status: number, data?: string): ResultNotificationCopySmsOrderAction => ({
    type: OrderNotificationActionEnum.RESULT_COPY_SMS,
    status,
    data
  }),
  initNotification: (): InitNotificationOrderAction => ({
    type: OrderNotificationActionEnum.INIT_NOTIFICATION
  })
};
export const OrderPaymentLinkActionCreators = {
  getPaymentLink: (orderId: number): GetPaymentLinkAction => ({
    type: OrderPaymentLinkActionEnum.REQUEST_PAYMENTLINK,
    orderId
  }),
  resultPaymentLink: (link: string): ResultGetPaymentLinkAction => ({
    type: OrderPaymentLinkActionEnum.RESULT_PAYMENTLINK,
    link
  })
};
