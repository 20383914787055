import { FC } from 'react';
import { Backdrop, Button, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import SimpleSelect from 'src/components/Items/select/SimpleSelect';
import { getNames } from 'src/common/utils/others';
import { useActions } from 'src/hooks/useActions';
import { ILocationFetch } from 'src/models/ILocation';

interface IProps {
  open: boolean;
  handleClose: () => void;
  locations: ILocationFetch[];
  cloneLocation: string;
  selectLocation: string;
  setSelectLocation: any;
}

const CloneSubPriceModal: FC<IProps> = ({
  open,
  handleClose,
  locations,
  cloneLocation,
  selectLocation,
  setSelectLocation
}) => {
  const { addSublocations } = useActions();

  const handleSubmit = () => {
    const selectedLocation = locations.find(l => l.name === selectLocation);
    const clonedLocation = locations.find(l => l.name === cloneLocation);
    const sublocations = clonedLocation?.sub_location!.map(sub => {
      return {
        name: sub.name,
        location_id: selectedLocation!.id,
        main_location_name: selectedLocation!.name
      };
    });
    addSublocations(sublocations!);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} BackdropComponent={Backdrop} closeAfterTransition>
      <div className='modal modal-450'>
        <div className='modal__title'>Clone Sublocations and Price</div>
        <SimpleSelect
          data={selectLocation}
          setData={setSelectLocation}
          dataArray={getNames(locations)}
          placeholder='location'
          title='location'
        />
        <div className='col-2'>
          <SecondTypeButton children='Cancel' onClick={handleClose} sx={{ marginTop: '30px' }} />
          <Button
            sx={{ marginTop: 3.5 }}
            variant='contained'
            onClick={handleSubmit}
            children='Clone'
            disabled={!selectLocation}
          />
        </div>
      </div>
    </Modal>
  );
};
export default CloneSubPriceModal;
