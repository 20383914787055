import { FC } from 'react';
import { Backdrop, Modal } from '@mui/material';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import './styles.scss';

const ChargeModal: FC<{
  open: boolean;
  handleClose: () => void;
  text: string;
}> = ({ handleClose, open, text }) => {
  return (
    <Modal open={open} onClose={handleClose} BackdropComponent={Backdrop} closeAfterTransition>
      <>
        <div className='modal modal-500 charge-modal'>
          <div className='modal__title'>Card Charged</div>
          <div className='modal__text charge-modal__text'>{text}</div>
          <div className='charge-modal__action'>
            <SecondTypeButton children='Close' onClick={() => handleClose()} />
          </div>
        </div>
      </>
    </Modal>
  );
};
export default ChargeModal;
