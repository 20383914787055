import { FC, useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { orderBy } from "natural-orderby";
import { CircularProgress, Tooltip } from "@mui/material";
import DeleteModalForm from "src/components/Items/DeleteModalForm";
import ActionForm from "src/components/Items/ActionForm";
import Table from 'src/components/Items/table';
import SeasonPriceModal from "src/pages/Admin/Modals/SeasonPriceModal";
import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useShowSnackbar from "src/hooks/useShowSnackbar";
import { ISeasonPriceFetch } from "src/models/ISeasonPrice";

const SeasonPriceTable: FC = () => {

  const { getSeasonPrice, clearSeasonPriceResult } = useActions();
  const {
    seasonPrice: { seasonPriceList, isLoading, result },
  } = useTypedSelector((state) => state);
  useShowSnackbar(isLoading, result, clearSeasonPriceResult, 'Season price');

  useEffect(() => {
    getSeasonPrice();
  }, []);

  useEffect(() => {
    (result.add || result.update || result.delete) && (
      getSeasonPrice()
    );
  }, [result.add, result.update, result.delete]);

  const [selectedSeasonPrice, setSelectedSeasonPrice] = useState({} as ISeasonPriceFetch);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = (order: ISeasonPriceFetch) => {
    setOpenEdit(true);
    setSelectedSeasonPrice(order);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = (order: ISeasonPriceFetch) => {
    setOpenDelete(true);
    setSelectedSeasonPrice(order);
  };

  const sortedSeasonPrice = orderBy(
    seasonPriceList,
    [(seasonPrice) => seasonPrice.location],
    ['asc', 'asc']
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'location',
        Cell: ({
          row: {
            original: { location },
          },
        }: any) => {
          return <>{location}</>;
        },
      },
      {
        Header: 'Start',
        accessor: 'start_date',
        Cell: ({
          row: {
            original: { start_date, timeOffset },
          },
        }) => (
          <>{`${DateTime.fromISO(start_date, { zone: 'UTC' })
            .plus({ minutes: timeOffset })
            .toFormat('DD')}`}</>
        ),
      },
      {
        Header: 'End',
        accessor: 'end_date',
        Cell: ({
          row: {
            original: { end_date, timeOffset },
          },
        }) => (
          <>{`${DateTime.fromISO(end_date, { zone: 'UTC' })
            .plus({ minutes: timeOffset })
            .toFormat('DD')}`}</>
        ),
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        Cell: ({
          row: {
            original: { rate },
          },
        }: any) => {
          return <>{rate}%</>;
        },
      },
      {
        accessor: 'actions',
        Cell: ({ row: { original } }: any) => (
          <>
            <div className='flex_CC' style={{ justifyContent: 'right' }}>
              <Tooltip title='Edit season price'>
                <>
                  <ActionForm.IconSet
                    title='season price'
                    classWrapper='action-wrapper'
                    onEdit={() => handleOpenEdit(original)}
                    onDelete={() => handleOpenDelete(original)}
                  />
                </>
              </Tooltip>
            </div>
          </>
        ),
      },
    ],
    [seasonPriceList]
  );

  return (
    <>
      <SeasonPriceModal
        open={openEdit}
        editSeasonPrice={selectedSeasonPrice}
        handleClose={() => setOpenEdit(false)}
      />
      <DeleteModalForm
        id={selectedSeasonPrice.id}
        type="season price"
        handleClose={() => setOpenDelete(false)}
        open={openDelete}
      />
      {isLoading.all ? (
        <CircularProgress size={200}
          style={{ position: 'fixed', top: '40%', left: '45%', zIndex: 100 }}
        />
      ) : (
        seasonPriceList.length ? (
          <div className='order-table'>
            <Table.Base
              data={sortedSeasonPrice}
              columns={columns}
              class='react-table'
              total={0}
            />
          </div>
        ) : null
      )}
    </>
  );
};
export default SeasonPriceTable;
